import './mobilenav.css';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/Context';
import { useContext, useState } from 'react';

import { normalAxios } from '../../config/axiosConfig';

export default function MobileNav() {
  const { user, dispatch } = useContext(UserContext);
  const handleClick = async (e) => {
    e.preventDefault();
    try {
      await normalAxios.post('/auth/logout', {
        username: user.username,
      });
      dispatch({ type: 'LOGOUT' });
      window.location.replace('/login');
    } catch (err) {
      console.log(err);
    }
  };
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  window.addEventListener('scroll', function () {
    if (open) {
      setOpen(false);
    }
  });

  document.addEventListener('click', (e) => {
    if (!document.getElementById('hamburgermenu').contains(e.target)) {
      setOpen(false);
    }
  });

  return (
    <div className='hamburger-menu' id='hamburgermenu'>
      {' '}
      {/* <input id="menu-toggle" type="checkbox" /> */}{' '}
      <button
        className={open ? 'menu-btn rotate' : 'menu-btn'}
        // for="menu-toggle"
        tabIndex='0'
        onClick={toggleOpen}
      >
        <span> </span>{' '}
      </button>{' '}
      <div className={open ? 'nav-content' : 'inactive'}>
        {' '}
        {user && (
          <div className='user-info'>
            <p> Hi, {user.username} </p>{' '}
            <button className='mobile-logout-button' onClick={handleClick}>
              Logout{' '}
            </button>{' '}
          </div>
        )}{' '}
        <ul className='menu-ul'>
          <li className='menu-li'>
            {' '}
            <Link className='menu-button' to='/' onClick={toggleOpen}>
              HOME{' '}
            </Link>{' '}
          </li>{' '}
          <li className='menu-li'>
            {' '}
            <Link className='menu-button' to='/notes' onClick={toggleOpen}>
              NOTES{' '}
            </Link>{' '}
          </li>{' '}
          {user && (
            <li className='menu-li'>
              {' '}
              <Link
                className='menu-button'
                to='/add-notes'
                onClick={toggleOpen}
              >
                ADD{' '}
              </Link>{' '}
            </li>
          )}{' '}
          <li className='menu-li'>
            {' '}
            <Link className='menu-button' to='/contact' onClick={toggleOpen}>
              CONTACT{' '}
            </Link>{' '}
          </li>{' '}
        </ul>{' '}
        {!user && (
          <div className='mobile-button'>
            <Link className='menu-button' to='/login' onClick={toggleOpen}>
              <button className='mobile-login-button'> Login </button>{' '}
            </Link>{' '}
            <Link className='menu-button' to='/register' onClick={toggleOpen}>
              <button className='mobile-register-button'> Register </button>{' '}
            </Link>{' '}
          </div>
        )}{' '}
      </div>{' '}
    </div>
  );
}
