const validate = (contact, user) => {
    const errors = {};

    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (user) {
        if (!user.username) {
            errors.username = 'Username is required!';
        }
        if (!user.password) {
            errors.password = 'Password is required!';
        }
    }

    if (contact) {
        if (!contact.fullName) {
            errors.name = 'A name is required!';
        }
        if (!contact.contactEmail) {
            errors.contactEmail = 'Email address is required!';
        } else if (!regexEmail.test(contact.contactEmail)) {
            errors.contactEmail = 'Email is not valid!';
        }
        if (!contact.subject) {
            errors.subject = 'A subject is required!';
        }
        if (!contact.message) {
            errors.message = 'Please leave a message!';
        }
    }

    return errors;
};

export default validate;