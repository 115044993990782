import { axiosConfiged } from '../../config/axiosConfig';
import { useContext, useState } from 'react';
import './addnote.css';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/Context';
import { getToken } from '../../config/function';

export default function AddNote() {
  const [title, setTitle] = useState('');
  const [problemUrl, setProblemUrl] = useState('');
  const [categories, setCategories] = useState('');
  const [content, setContent] = useState('');
  const [file, setFile] = useState(null);
  const [tag, setTag] = useState('Easy');
  const { user } = useContext(UserContext);
  const [message, setMessage] = useState('');
  // const [updateToken, setUpdateToken] = useState(false);

  const token = getToken();

  const handleClick = (e) => {
    e.preventDefault();
    const inputFile = document.getElementById('fileinput');
    inputFile.click();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const cat = categories.split(',');
    const newNote = {
      title,
      problemUrl,
      content,
      tag,
      categories: cat,
      username: user.username,
    };
    if (file) {
      const data = new FormData();
      const filename = file.name;
      data.append('name', filename);
      data.append('file', file);
      try {
        const url = await axiosConfiged.post('/image', data, {
          headers: { authorization: `Bearer ${token}` },
        });
        newNote.photo = url.data;
        // setUpdateToken(true);
        console.log(token);
      } catch (err) {
        console.log(err.response);
        const errorFile = err.response.data;
        setMessage(errorFile.message);
      }
    }

    // const dat = {
    //   userid: `${user._id}`,
    //   refreshtoken: `${user.refreshtoken}`,
    // };

    // try {
    //   console.log(dat);
    //   const res = await axios.post('/refresh', dat);
    //   dispatch({ type: 'LOGIN_SUCCESS', payload: res.data });
    //   console.log(res);
    //   setUpdateToken(true);
    //   console.log('first');
    //   console.log(user);
    // } catch (err) {
    //   console.log(err);
    // }

    try {
      // console.log(`Bearer ${user.token}`);
      const newtoken = getToken();
      // const newtoken = JSON.parse(localStorage.getItem('user')).token;
      // console.log(newtoken);
      const res = await axiosConfiged.post('/notes', newNote, {
        headers: { authorization: `Bearer ${newtoken}` },
      });

      // setUpdateToken(true);
      // console.log(res);
      // console.log(res);
      // console.log("/note/" + res.data._id);
      // window.location = "http://localhost:3000/note/" + res.data.slug;

      window.location.replace('/note/' + res.data.slug);
    } catch (err) {
      console.log(err);
      // const errorNote = err.response.data;
      // setMessage(errorNote.message);
    }
  };

  return (
    <div className='addnote-page'>
      <h1 className='add-form-title'> Add a new note </h1>{' '}
      <form action='' className='add-form' onSubmit={handleSubmit}>
        {file && (
          <img
            className='note-img'
            src={URL.createObjectURL(file)}
            alt='note-img'
          />
        )}{' '}
        <div className='form-content'>
          {' '}
          {/* <label className="row-input">
                                                                        <span className="input-title"> Title: </span>{" "} */}{' '}
          <input
            type='text'
            name='title'
            className='normal-input'
            autoFocus={true}
            placeholder='Note title'
            onChange={(e) => setTitle(e.target.value)}
          />{' '}
          <input
            type='text'
            name='problemUrl'
            className='normal-input'
            placeholder='Note link'
            onChange={(e) => setProblemUrl(e.target.value)}
          />{' '}
          <input
            type='text'
            name='categories'
            className='normal-input'
            placeholder='Categories, seperated by comma'
            onChange={(e) => setCategories(e.target.value)}
          />{' '}
          <textarea
            className='normal-input'
            name='content'
            id=''
            cols='30'
            rows='30'
            placeholder='Write down the details'
            type='text'
            onChange={(e) => setContent(e.target.value)}
          ></textarea>{' '}
        </div>{' '}
        <div className='form-content-radio'>
          <button
            className={tag === 'Easy' ? 'checkbox active-box' : 'checkbox'}
            onClick={(e) => {
              e.preventDefault();
              setTag('Easy');
            }}
          >
            Easy{' '}
          </button>{' '}
          <button
            className={tag === 'Easy+' ? 'checkbox active-box' : 'checkbox'}
            onClick={(e) => {
              e.preventDefault();
              setTag('Easy+');
            }}
          >
            Easy+
          </button>{' '}
          <button
            className={tag === 'Medium' ? 'checkbox active-box' : 'checkbox'}
            onClick={(e) => {
              e.preventDefault();
              setTag('Medium');
            }}
          >
            Medium{' '}
          </button>{' '}
          <button
            className={tag === 'Medium+' ? 'checkbox active-box' : 'checkbox'}
            onClick={(e) => {
              e.preventDefault();
              setTag('Medium+');
            }}
          >
            Medium+
          </button>{' '}
          <button
            className={tag === 'Hard' ? 'checkbox active-box' : 'checkbox'}
            onClick={(e) => {
              e.preventDefault();
              setTag('Hard');
            }}
          >
            Hard{' '}
          </button>{' '}
          <button
            className={tag === 'Hard+' ? 'checkbox active-box' : 'checkbox'}
            onClick={(e) => {
              e.preventDefault();
              setTag('Hard+');
            }}
          >
            Hard+
          </button>{' '}
        </div>
        <div className='form-content-button'>
          <button onClick={handleClick} className='addimg-button'>
            <i className='fa-solid fa-image icon-addpage'> </i>{' '}
          </button>{' '}
          <input
            type='file'
            name='photo'
            id='fileinput'
            onChange={(e) => setFile(e.target.files[0])}
          />{' '}
          <button
            className='note-submit-button'
            type='submit'
            onClick={handleSubmit}
          >
            <i className='fa-solid fa-floppy-disk icon-addpage'> </i>{' '}
          </button>{' '}
          <Link to='/notes'>
            <button className='note-submit-button' type='submit'>
              <i className='fa-solid fa-rectangle-xmark icon-addpage'> </i>{' '}
            </button>{' '}
          </Link>{' '}
        </div>{' '}
      </form>{' '}
      <p className='addpage-error'> {message} </p>{' '}
    </div>
  );
}
