import './note.css';
import { Link } from 'react-router-dom';

export default function Note({ note }) {
  // // const handleClick = (id) => {
  // //   window.location = "http://localhost:3000/note/" + id;
  // // }

  // console.log(`/note/${note._id}`);
  // const PF = "http://localhost:4000/images/";
  return (
    <div className='note'>
      <div className='note-title'>
        <Link to={`/note/${note.slug}`} className='react-link'>
          <p className='note-title-p'>{note.title}</p>
        </Link>
      </div>
      <div className='note-link'>
        <a href={note.problemUrl} className='problem-url'>
          {' '}
          Link{' '}
        </a>
      </div>
      <div className='note-tag-div'>
        <p className={`note-tag note-tag-${note.tag}`}>{note.tag}</p>
      </div>

      <div className='note-catg'>
        <ul className='note-catg-list'>
          {note.categories.map((cat) => (
            <li key={cat} className='catg-list-item'>
              {' '}
              {cat}{' '}
            </li>
          ))}
        </ul>
      </div>
      <div className='note-desc'> {note.content} </div>
      <div className='note-view-link'>
        <Link to={`/note/${note.slug}`}>
          <button className='note-view-button'> View </button>
        </Link>
      </div>
    </div>
  );
}
