import { createContext, useEffect, useReducer, useState } from 'react';
import Reducer from './Reducer';

const INITIAL_STATE = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  isFetching: false,
  error: false,
};

export const UserContext = createContext(INITIAL_STATE);

const UserContextProvider = ({ children }) => {
  const [updateToken, setUpdateToken] = useState(false);
  const [state, dispatch] = useReducer(Reducer, INITIAL_STATE);
  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(state.user));
  }, [state.user]);
  return (
    <UserContext.Provider
      value={{
        user: state.user,
        isFetching: state.isFetching,
        error: state.error,
        dispatch,
        setUpdateToken,
      }}
    >
      {' '}
      {children}{' '}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
