import { Routes, Route } from 'react-router-dom';
import React, { useContext } from 'react';
import { UserContext } from './context/Context';
import TopBar from './components/topbar/TopBar';
import Notes from './pages/notes/Notes';
import AddNote from './pages/addnote/AddNote';
import Contact from './pages/contact/Contact';
import Footer from './components/footer/Footer';
import Home from './pages/home/Home';
import './app.css';
import SingleNote from './components/singlenote/SingleNote';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import Error from './pages/error/Error';
// import DataStructure from './pages/datastructure/DataStructure';
import SignUp from './pages/signup/SignUp';

import { BrowserRouter as Router } from 'react-router-dom';
// "proxy": "http://localhost:4000/",
function App() {
  const user = useContext(UserContext);
  return (
    <Router>
      <div className='app-page'>
        <TopBar />
        <div className='app-body'>
          <Routes>
            <Route exact path='/' element={<Home />} />{' '}
            <Route exact path='/notes' element={<Notes />} />{' '}
            <Route
              exact
              path='/add-notes'
              element={user.user ? <AddNote /> : <Home />}
            />{' '}
            <Route exact path='/contact' element={<Contact />} />{' '}
            {/* <Route exact path='/datastructure' element={<DataStructure />} />{' '} */}
            <Route exact path='/note/:slug' element={<SingleNote />} />{' '}
            <Route
              exact
              path='/login'
              element={!user.user ? <Login /> : <Notes />}
            />{' '}
            <Route
              exact
              path='/register'
              element={!user.user ? <Register /> : <Notes />}
            />{' '}
            <Route exact path='/signup' element={<SignUp />} />{' '}
            <Route exact path='*' element={<Error />} />{' '}
          </Routes>{' '}
        </div>{' '}
        <Footer />{' '}
      </div>{' '}
    </Router>
  );
}

export default App;
