import './error.css';
import errorImg from '../../img/error.png';

export default function Error() {
  return (
    <div className='error-page'>
      <img src={errorImg} alt='error img' />
      <p className='error-page-message'>Page not found!</p>
    </div>
  );
}
