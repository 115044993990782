import Note from '../../components/note/Note';
import './notes.css';
import SideBar from '../../components/sidebar/Sidebar';
import Pagination from '../../components/pagination/Pagination';
import { useState, useEffect } from 'react';
import { normalAxios } from '../../config/axiosConfig';
import { useLocation } from 'react-router-dom';
import NoteListView from '../../components/NoteListView/NoteListView';

export default function Notes() {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [notesPerPage, setNotesPerPage] = useState(8);
  const [error, setError] = useState('');
  const [allnotes, setAllNotes] = useState([]);

  const { search } = useLocation();
  const [keyWord, setKeyWord] = useState('');

  const [view, setView] = useState('grid');
  const [title, setTitle] = useState('All notes');

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        if (search.length !== 0) {
          const titleBody = search.split('=')[1];
          const titleArray = titleBody.split('%20');
          let titleRes = titleArray.join(' ');
          let resultTitle = titleRes.replace('%2B', '+');
          setTitle(resultTitle);
        }
        const res = await normalAxios.get('/notes' + search);
        const sortedNotes = res.data.sort((a, b) =>
          a.title > b.title ? 1 : -1
        );
        setTimeout(() => {
          setLoading(false);
          setError('');
          setNotes(sortedNotes);
          setAllNotes(sortedNotes);
        }, 800);
      } catch (err) {
        setNotes([]);
        setLoading(false);
        const errorMessage = err.response.data;
        setError(errorMessage.message);
      }
    };
    fetchNotes();
  }, [search]);

  // const handleSearchChange = (e) => {
  //   setKeyWord(e.target.value);
  //   const searchedNotes = allnotes.filter((note) =>
  //     note.title.toLowerCase().includes(keyWord)
  //   );
  //   setNotes(searchedNotes);
  // };
  const handleSearch = (e) => {
    e.preventDefault();
    const searchInput = document.getElementById('searchinput');
    const searchedNotes = allnotes.filter((note) =>
      note.title.toLowerCase().includes(keyWord.toLocaleLowerCase())
    );
    searchInput.value = '';
    setKeyWord('');
    setNotes(searchedNotes);
  };
  const handleReset = () => {
    setNotes(allnotes);
  };
  const indexOfLastNote = currentPage * notesPerPage;
  const indexOfFirstNote = indexOfLastNote - notesPerPage;
  const currentNotes = notes.slice(indexOfFirstNote, indexOfLastNote);
  const totalNotes = notes.length;
  const totalPages = Math.ceil(totalNotes / notesPerPage);

  return (
    <div className='notes-page'>
      <div className='sidebar-section'>
        <SideBar setTitle={setTitle} />{' '}
      </div>{' '}
      <div className='notes-wrapper'>
        {loading && (
          <div className='fetching-div'>
            <div className='spinner'></div>
            {/* <p className='spinner-title'>Thank you for your patience!</p> */}
          </div>
        )}
        {error && <div className='error-message-div'>{error}</div>}
        {!loading && (
          <div className='searchbar'>
            <input
              type='text'
              name='search-query'
              className='search-input'
              id='searchinput'
              placeholder='Search by keyword'
              onChange={(e) => setKeyWord(e.target.value)}
            />
            <button className='search-button' onClick={handleSearch}>
              Search
            </button>
            <button className='reset-button' onClick={handleReset}>
              Reset
            </button>
          </div>
        )}
        {!loading && !error && (
          <div className='notes-title-section'>
            <label className='notes-cat-title'>
              {' '}
              {title}
              <b className='notes-number'>{'(' + notes.length + ')'}</b>
            </label>{' '}
            <div className='notes-title-buttons'>
              <i
                className={
                  view === 'list'
                    ? 'fa-solid fa-list chosen'
                    : 'fa-solid fa-list'
                }
                onClick={() => {
                  setView('list');
                  setNotesPerPage(10);
                }}
              ></i>{' '}
              <i
                className={
                  view === 'grid'
                    ? 'fa-solid fa-grip chosen'
                    : 'fa-solid fa-grip'
                }
                onClick={() => {
                  setView('grid');
                  setNotesPerPage(8);
                }}
              ></i>{' '}
            </div>{' '}
          </div>
        )}{' '}
        {view === 'grid' ? (
          <div className='notes'>
            {' '}
            {currentNotes.map((note) => (
              <Note key={note.title} note={note} />
            ))}{' '}
          </div>
        ) : (
          <div className='notes-list'>
            {' '}
            {currentNotes.map((note) => (
              <NoteListView key={note._id} note={note} />
            ))}{' '}
          </div>
        )}{' '}
        {currentNotes.length > 0 && (
          <Pagination
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            currentNotes={currentNotes}
            totalNotes={totalNotes}
          />
        )}{' '}
      </div>{' '}
    </div>
  );
}
