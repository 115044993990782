import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { getToken, getRefreshToken } from './function';

const axiosConfiged = axios.create({
    baseURL: process.env.REACT_APP_DEV_MODE === 'Dev' ?
        process.env.REACT_APP_DEV_URL :
        process.env.REACT_APP_PROD_URL,
});
const normalAxios = axios.create({
    baseURL: process.env.REACT_APP_DEV_MODE === 'Dev' ?
        process.env.REACT_APP_DEV_URL :
        process.env.REACT_APP_PROD_URL,
});

// if (process.env.DEV_MODE === 'Dev') {
//     axiosConfiged.defaults.baseURL = process.env.DEV_URL;

//     normalAxios.defaults.baseURL = process.env.DEV_URL;
// } else if (process.env.DEV_MODE === 'Prod') {
//     axiosConfiged.defaults.baseURL = process.env.PROD_URL;
//     normalAxios.defaults.baseURL = process.env.PROD_URL;
// }

const refreshToken = async() => {
    const userItem = JSON.parse(localStorage.getItem('user'));
    if (!userItem) {
        return Promise.reject('Not logged in!');
    }
    const refToken = {
        userid: userItem._id,
        refreshtoken: getRefreshToken(),
    };
    try {
        const res = await normalAxios.post('/refresh', refToken);
        localStorage.setItem('user', JSON.stringify(res.data));
        return res.data;
    } catch (err) {
        console.log(err);
    }
};

axiosConfiged.interceptors.request.use(
    async(config) => {
        let currentDate = new Date();
        const curToken = getToken();
        if (curToken) {
            const decodedToken = jwt_decode(curToken);
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                const res = await refreshToken();
                config.headers['authorization'] = 'Bearer ' + res.token;
            }
            return config;
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

export { axiosConfiged, normalAxios };