import './notelistview.css';
import { Link } from 'react-router-dom';

export default function NoteListView({ note }) {
  const handleClick = () => {
    window.location.replace(`/note/${note.slug}`);
  };
  return (
    <div className='note-list' onClick={handleClick}>
      <Link className='note-list-title' to={`/note/${note.slug}`}>
        <p>{note.title}</p>
      </Link>
      <div className='note-list-right'>
        <p className={`note-list-tag note-list-${note.tag}`}>{note.tag}</p>
        <a className='note-list-link' href={note.problemUrl}>
          Link
        </a>
      </div>
    </div>
  );
}
