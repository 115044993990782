import './login.css';
import { Link } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../context/Context';
import { normalAxios } from '../../config/axiosConfig';
import validate from '../../services/services';
export default function Login() {
  // const userRef = useRef();
  // const passwordRef = useRef();
  const [loginuser, setLoginUser] = useState({
    username: '',
    password: '',
  });
  const { dispatch, isFetching } = useContext(UserContext);
  const [message, setMessage] = useState('');
  const [validated, setValidated] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginUser((prevUser) => {
      return {
        ...prevUser,
        [name]: value,
      };
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(null, loginuser));
    setValidated(true);
  };

  useEffect(async () => {
    if (Object.keys(formErrors).length === 0 && validated) {
      dispatch({ type: 'LOGIN_START' });

      try {
        const res = await normalAxios.post('/auth/login', loginuser);

        dispatch({ type: 'LOGIN_SUCCESS', payload: res.data });

        window.location.replace('/notes');
      } catch (err) {
        const error = err.response.data;
        setMessage(error.message);
        dispatch({ type: 'LOGIN_FAIL' });
      }
    }
  }, [formErrors]);

  return (
    <div className='login-page'>
      <div className='login'>
        <h1 className='login-form-title'> Login </h1>{' '}
        <form action='' className='login-form' onSubmit={handleSubmit}>
          <label className='login-form-label'> Username </label>{' '}
          <input
            className='login-input'
            type='text'
            name='username'
            placeholder='Enter your username'
            onChange={handleChange}
          />{' '}
          <p className='login-form-error'>{formErrors.username}</p>
          <label className='login-form-label'> Password </label>{' '}
          <input
            className='login-input'
            type='password'
            name='password'
            placeholder='Enter your password'
            onChange={handleChange}
          />{' '}
          <p className='login-form-error'>{formErrors.password}</p>
          <button className='form-login-button' type='submit'>
            Login{' '}
          </button>{' '}
        </form>{' '}
        <p className='form-addon-info'> Don 't have an account? </p>{' '}
        <Link to='/register'>
          <button className='form-register-button'> Register </button>{' '}
        </Link>{' '}
        {message && <p className='message-area'> {message} </p>}{' '}
      </div>
    </div>
  );
}
