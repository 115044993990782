// import { useState } from 'react';
import './register.css';
// import axios from 'axios';
import oopsImg from '../../img/Oops.png';
import { Link } from 'react-router-dom';

export default function Register() {
  // const [username, setUserName] = useState('');
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [message, setMessage] = useState('');

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const res = await axios.post('/auth/register', {
  //       username,
  //       email,
  //       password,
  //     });
  //     setMessage(res.data);
  //     setTimeout(() => {
  //       window.location.replace('/login');
  //     }, 2000);
  //   } catch (err) {
  //     const error = err.response.data;
  //     setMessage(error.message);
  //   }
  // };

  return (
    <div className='register'>
      <img src={oopsImg} alt='not-available' className='register-img' />
      <div className='registerinfo'>
        <h1 className='register-info-header'>Oop!</h1>
        <p className='register-info-body'>
          The register service is currently not open. If you like the content,
          drop a message or sign up!
        </p>

        <div className='register-info-button'>
          <Link to='/contact'>
            <button className='register-contact-button '> Contact </button>{' '}
          </Link>{' '}
          <Link to='/signup'>
            <button className='register-signup-button'> Sign Up </button>{' '}
          </Link>{' '}
        </div>
      </div>
      {/* <h1 className='register-form-title'> Register </h1>{' '}
      <form action='' className='register-form' onSubmit={handleSubmit}>
        <label htmlFor=''> User Name </label>{' '}
        <input
          className='register-input'
          type='text'
          placeholder='Enter your username'
          onChange={(e) => setUserName(e.target.value)}
        />{' '}
        <label htmlFor=''> Email </label>{' '}
        <input
          className='register-input'
          type='email'
          placeholder='Enter your email'
          onChange={(e) => setEmail(e.target.value)}
        />{' '}
        <label htmlFor=''> Password </label>{' '}
        <input
          className='register-input'
          type='password'
          placeholder='Enter your password'
          onChange={(e) => setPassword(e.target.value)}
        />{' '}
        <button className='register-submit-button' type='submit'>
          Create Account{' '}
        </button>{' '}
      </form>{' '}
      {message && <p className='register-error-message'> {message} </p>}{' '} */}
    </div>
  );
}
