import './topbar.css';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/Context';
import { useContext, useEffect, useState } from 'react';
import MobileNav from '../mobilenav/MobileNav';
import { normalAxios } from '../../config/axiosConfig';

export default function TopBar() {
  const { user, dispatch } = useContext(UserContext);
  const handleClick = async (e) => {
    e.preventDefault();
    try {
      await normalAxios.post('/auth/logout', { username: user.username });
      dispatch({ type: 'LOGOUT' });
      window.location.replace('/login');
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {}, [user]);
  const [scroll, setScroll] = useState(false);

  const updateScroll = () => {
    if (window.scrollY >= 80) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  window.addEventListener('scroll', updateScroll);

  return (
    <header className={scroll ? 'topbar active' : 'topbar'}>
      <a href='#main-content' className='skip-button'>
        Skip to main content{' '}
      </a>{' '}
      <div className='topbar-wrapper'>
        <div className='topbar-logo'>
          <h1 className='website-title'> AlgoNote </h1>{' '}
        </div>{' '}
        <div className='topbar-hamburger'>
          <MobileNav />
        </div>{' '}
        <div className='topbar-link'>
          {' '}
          {/* <div className="topcenter"> */}{' '}
          <nav className='navbar'>
            <ul className='menu'>
              {' '}
              <li>
                {' '}
                <Link className='menu-button' to='/'>
                  HOME{' '}
                </Link>{' '}
              </li>{' '}
              <li>
                {' '}
                <Link className='menu-button' to='/notes'>
                  NOTES{' '}
                </Link>{' '}
              </li>{' '}
              {user && (
                <li>
                  {' '}
                  <Link className='menu-button' to='/add-notes'>
                    ADD{' '}
                  </Link>{' '}
                </li>
              )}{' '}
              <li>
                {' '}
                <Link className='menu-button' to='/contact'>
                  CONTACT{' '}
                </Link>{' '}
              </li>{' '}
            </ul>{' '}
          </nav>{' '}
          {/* </div>{" "} */}{' '}
          {!user && (
            <div className='topbar-button'>
              <Link className='menu-button' to='/login'>
                <button className='login-button'> Login </button>{' '}
              </Link>{' '}
              <Link className='menu-button' to='/register'>
                <button className='register-button'> Register </button>{' '}
              </Link>{' '}
            </div>
          )}{' '}
          {user && (
            <div className='topbar-button'>
              <p className='user-name'> Hi, {user.username} </p>{' '}
              <button className='logout-button' onClick={handleClick}>
                Logout{' '}
              </button>{' '}
            </div>
          )}{' '}
        </div>{' '}
      </div>{' '}
      <div id='main-content'> </div>{' '}
    </header>
  );
}
