import './home.css';
import { Link } from 'react-router-dom';
import heroImg from '../../img/hero.png';

import avatar from '../../img/avatar.png';

export default function Home() {
  return (
    <div className='home-page'>
      {' '}
      {/* <TopBar /> */} {/* <Header /> */}{' '}
      <div className='hero-wrapper'>
        <div className='hero'>
          <div className='hero-text'>
            <div className='text-body'>
              <p className='hero-first-title'>
                {' '}
                Algorithm problems are difficult{' '}
              </p>{' '}
              <p className='hero-title'> Share your ideas ? </p>{' '}
              <Link to='/notes'>
                <button className='hero-button'> View Notes </button>{' '}
              </Link>{' '}
            </div>{' '}
          </div>{' '}
          <div className='hero-img'>
            <img src={heroImg} alt='' className='hero-image' />
          </div>{' '}
        </div>
      </div>
      {/* <div className='ds-content'>
        <div className='ds-text'>
          <div className='text-body'>
            <p className='hero-title'> Eight Common Data Structures </p>{' '}
            <Link to='/datastructure'>
              <button className='hero-button'> View All </button>{' '}
            </Link>{' '}
          </div>{' '}
        </div>{' '}
        <div className='ds-img'>
          <img src={herodataImg} alt='' className='hero-image' />
        </div>{' '}
      </div>{' '} */}
      <div className='algo-wrapper'>
        <div className='algorithms'>
          <p className='cat-title'> Algorithms & Types </p>{' '}
          <ul className='cat-ul-algo'>
            <li className='single-cat-algo'> Binary Search </li>{' '}
            <li className='single-cat-algo'> Depth First Search(DFS) </li>{' '}
            <li className='single-cat-algo'> Breadth First Search(BFS) </li>{' '}
            <li className='single-cat-algo'> Sort </li>{' '}
            <li className='single-cat-algo'> Kruskal </li>{' '}
            <li className='single-cat-algo'> Dijkstra </li>{' '}
            <li className='single-cat-algo'> Knuth - Morris - Pratt </li>{' '}
            <li className='single-cat-algo'> Euclid </li>{' '}
            <li className='single-cat-algo'> Bellman Ford </li>{' '}
            <li className='single-cat-algo'> Kadane </li>{' '}
            <li className='single-cat-algo'> Union Find </li>{' '}
            <li className='single-cat-algo'> Topological Sort </li>{' '}
            <li className='single-cat-algo'> Two Pointer </li>{' '}
            <li className='single-cat-algo'> Backtracking </li>{' '}
            <li className='single-cat-algo'> Dynamic Programming </li>{' '}
            <li className='single-cat-algo'> Divide & Conquer </li>{' '}
            <li className='single-cat-algo'> Greedy </li>{' '}
            <li className='single-cat-algo'> Brute force </li>{' '}
          </ul>{' '}
        </div>{' '}
      </div>
      <div className='contact-wrapper'>
        <div className='contact-section'>
          <h1 className='footer-contact-header'> About Me </h1>{' '}
          <div className='about-me'>
            <img src={avatar} alt='avatar-img' className='avatar-img' />
            <div className='icon-buttons'>
              <a href='https://www.linkedin.com/in/yajianzhang/'>
                <i className='icon-button fa-brands fa-linkedin-in'> </i>{' '}
              </a>{' '}
              <a href='https://github.com/alaizyj'>
                <i className='icon-button fa-brands fa-github'> </i>{' '}
              </a>{' '}
              <a href='https://alaizyj.github.io/Personal-Website/'>
                <i className='icon-button fa-solid fa-user'> </i>{' '}
              </a>{' '}
            </div>{' '}
            <p className='about-intro'>Hi, my name is Yajian</p>
            <p className='about-intro'>I am a Full Stack Developer</p>
          </div>
          <div className='contact-bottom-section'>
            <p className='contactsection-message'>
              If you like AlgoNote, drop a message or signup!
            </p>
            <div className='contact-button'>
              <Link to='/contact'>
                <button className='footer-contact-button '> Contact </button>{' '}
              </Link>{' '}
              <Link to='/signup'>
                <button className='footer-signup-button'> Sign Up </button>{' '}
              </Link>{' '}
            </div>{' '}
          </div>
        </div>{' '}
      </div>
    </div>
  );
}
