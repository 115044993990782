import './footer.css';

export default function Footer() {
  return (
    <footer>
      <div className='footer-section'>
        <div className='footer-button'>
          <a href='https://www.linkedin.com/in/yajianzhang/'>
            <i className='fa-brands fa-linkedin-in'> </i>{' '}
          </a>{' '}
          <a href='https://github.com/alaizyj'>
            <i className='fa-brands fa-github'> </i>{' '}
          </a>{' '}
          <a href='https://alaizyj.github.io/Personal-Website/'>
            <i className='fa-solid fa-user'> </i>{' '}
          </a>{' '}
        </div>{' '}
        <p className='footer-p'> Copyright © Yajian Zhang 2022 </p>{' '}
      </div>{' '}
    </footer>
  );
}
