import './contact.css';
import { useEffect, useState } from 'react';
import { normalAxios } from '../../config/axiosConfig';
import validate from '../../services/services';

export default function Contact() {
  const [newmessage, setNewMessage] = useState({
    fullName: '',
    contactEmail: '',
    subject: '',
    message: '',
  });
  const [feedback, setFeedBack] = useState('');
  const [validated, setValidated] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewMessage((prevMessage) => {
      return {
        ...prevMessage,
        [name]: value,
      };
    });
  };
  const handleContactSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(newmessage, null));
    setValidated(true);
    setFeedBack('');
  };

  useEffect(async () => {
    if (Object.keys(formErrors).length === 0 && validated) {
      try {
        const res = await normalAxios.post('/contact', newmessage);
        setFeedBack(res.data);
        setNewMessage({
          fullName: '',
          contactEmail: '',
          subject: '',
          message: '',
        });

        setTimeout(() => {
          window.location.replace('/notes');
        }, 2000);
      } catch (err) {
        setFeedBack(err.response.data.message);
      }
    }
  }, [formErrors]);

  // const handleContactSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const res = await normalAxios.post('/contact', newmessage);
  //     setNewMessage({
  //       fullName: '',
  //       contactEmail: '',
  //       subject: '',
  //       message: '',
  //     });
  //     setFeedBack(res.data);
  //     setTimeout(() => {
  //       window.location.replace('/notes');
  //     }, 1000);
  //   } catch (err) {
  //     setFeedBack(err.response.data.message);
  //   }
  // };

  return (
    <div className='contact-page'>
      <h1 className='contact-page-header'> Contact </h1>{' '}
      <form action='' className='contact-form' onSubmit={handleContactSubmit}>
        <input
          type='text'
          name='fullName'
          className='contact-row-input'
          value={newmessage.fullName}
          placeholder='Full name'
          onChange={handleChange}
        ></input>
        <p className='contact-form-error'>{formErrors.name}</p>
        <input
          type='email'
          name='contactEmail'
          className='contact-row-input'
          value={newmessage.contactEmail}
          placeholder='Email'
          onChange={handleChange}
        ></input>
        <p className='contact-form-error'>{formErrors.contactEmail}</p>
        <input
          type='text'
          name='subject'
          className='contact-row-input'
          value={newmessage.subject}
          placeholder='Subject'
          onChange={handleChange}
        ></input>
        <p className='contact-form-error'>{formErrors.subject}</p>
        <textarea
          type='text'
          name='message'
          placeholder='Message'
          className='contact-row-input'
          value={newmessage.message}
          rows='10'
          cols='30'
          onChange={handleChange}
        ></textarea>
        <p className='contact-form-error'>{formErrors.message}</p>
        <button className='contactform-submit-button' type='submit'>
          Send
        </button>
      </form>
      <p className='submit-form-error'>{feedback}</p>
    </div>
  );
}
