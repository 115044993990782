import './singlenote.css';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { normalAxios } from '../../config/axiosConfig';
import { useContext } from 'react';
import { UserContext } from '../../context/Context';
import { Link } from 'react-router-dom';
import { axiosConfiged } from '../../config/axiosConfig';
import { getToken } from '../../config/function';

export default function SingleNote() {
  const location = useLocation();
  const slug = location.pathname.split('/')[2];
  const [note, setNote] = useState({});
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [problemUrl, setProblemUrl] = useState('');
  const [cat, setCat] = useState('');
  const [tag, setTag] = useState('');
  const [update, setUpdate] = useState(false);
  const { user } = useContext(UserContext);
  const [photo, setPhoto] = useState('');
  const [body, setBody] = useState([]);
  const [author, setAuthor] = useState('');
  const [file, setFile] = useState(null);
  // const [updateToken, setUpdateToken] = useState(false);
  // if (user) {
  //   const token = getToken();
  // }

  function splitString(stringContent) {
    return stringContent.split('\n');
  }

  useEffect(() => {
    const getNote = async () => {
      const res = await normalAxios.get('/notes/' + slug);
      setNote(res.data);
      setTitle(res.data.title);
      const cats = getString(res.data.categories);
      setCat(cats);
      setAuthor(res.data.username);
      setTag(res.data.tag);
      setPhoto(res.data.photo);
      setContent(res.data.content);
      setProblemUrl(res.data.problemUrl);
      setBody(splitString(res.data.content));
    };
    getNote();
  }, [slug]);

  function getString(cat) {
    return cat.join(',');
  }

  const handleDelete = async () => {
    try {
      // await axiosConfiged.delete('/notes/' + slug, {
      //   headers: { authorization: 'Bearer ' + user.token },
      // });
      const token = getToken();
      await axiosConfiged.delete('/notes/' + slug, {
        headers: { authorization: `Bearer ${token}` },
      });
      window.location.replace('/notes');
    } catch (err) {
      console.log(err.response.data.message);
    }
  };

  const handleUpdate = async () => {
    const token = getToken();
    const categories = cat.split(',');
    setUpdate(false);
    const updatedNote = {
      username: user.username,
      title,
      problemUrl,
      categories,
      content,
      tag,
    };
    if (file) {
      const data = new FormData();
      const filename = file.name;
      data.append('name', filename);
      data.append('file', file);
      try {
        const url = await axiosConfiged.post('/image', data, {
          headers: { authorization: `Bearer ${token}` },
        });
        updatedNote.photo = url.data;
        // setUpdateToken(true);
      } catch (err) {
        const errorFile = err.response.data;
        // setMessage(errorFile.message);
        console.log(errorFile.message);
      }
    }

    try {
      const newtoken = getToken();
      await axiosConfiged.put('/notes/' + slug, updatedNote, {
        headers: { authorization: `Bearer ${newtoken}` },
      });
      // console.log(res);
      // setUpdateToken(true);
      setUpdate(false);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancel = () => {
    setUpdate(false);
  };

  const handleClick = (e) => {
    e.preventDefault();
    const inputFile = document.getElementById('fileinput');
    inputFile.click();
  };
  return (
    <div className='singlenote'>
      <div className='singlenote-wrapper'>
        <img
          src={file ? URL.createObjectURL(file) : photo}
          alt={title}
          className='singlenote-img'
        />{' '}
        {update && (
          <div className='update-noteimg-button'>
            <button onClick={handleClick} className='editimg-button'>
              {' '}
              {/* <button className="add-img-button"> */}{' '}
              <i className='fa-solid fa-image icon-addpage'> </i>{' '}
              {/* </button>{" "} */}{' '}
            </button>{' '}
            <input
              type='file'
              name='photo'
              id='fileinput'
              onChange={(e) => setFile(e.target.files[0])}
            />{' '}
          </div>
        )}{' '}
      </div>{' '}
      {update ? (
        <div className='update-note-body'>
          <div className='update-single-div'>
            <label className='update-note-label'> Title: </label>{' '}
            <input
              className='update-row-input'
              value={title}
              type='text'
              onChange={(e) => setTitle(e.target.value)}
            ></input>{' '}
          </div>{' '}
          <div className='update-single-div'>
            <label className='update-note-label'> Link: </label>{' '}
            <input
              className='update-row-input'
              value={problemUrl}
              onChange={(e) => setProblemUrl(e.target.value)}
            ></input>{' '}
          </div>{' '}
          <div className='update-single-div'>
            <label className='update-note-label'> Category: </label>{' '}
            <input
              className='update-row-input'
              value={cat}
              onChange={(e) => setCat(e.target.value)}
            ></input>{' '}
          </div>{' '}
          <div className='update-single-div'>
            <label className='update-note-label'> Difficulty: </label>{' '}
            <select
              className='dropdown'
              name='tag'
              value={tag}
              onChange={(e) => setTag(e.target.value)}
            >
              <option value=''> Please select </option>{' '}
              <option value='Easy'> {'Easy'} </option>{' '}
              <option value='Easy+'> {'Easy+'} </option>{' '}
              <option value='Medium'> {'Medium'} </option>{' '}
              <option value='Medium+'> {'Medium+'} </option>{' '}
              <option value='Hard'> {'Hard'} </option>{' '}
              <option value='Hard+'> {'Hard+'} </option>{' '}
            </select>{' '}
          </div>{' '}
          <div className='update-content-div'>
            <label className='update-note-label'> Content: </label>{' '}
            <textarea
              className='update-textarea'
              value={content}
              cols='30'
              rows='20'
              onChange={(e) => setContent(e.target.value)}
              type='text'
            />
          </div>{' '}
          <div className='update-button-div'>
            <button className='update-confirm-button' onClick={handleUpdate}>
              {' '}
              Update{' '}
            </button>{' '}
            <button className='update-cancel-button' onClick={handleCancel}>
              {' '}
              Cancel{' '}
            </button>{' '}
          </div>{' '}
        </div>
      ) : (
        <div className='singlenote-body'>
          <div className='singlenote-header'>
            <b className='singlenote-title'> {title} </b>{' '}
            {user && user.username === author && (
              <div className='button-div'>
                <button className='wrap-button' onClick={() => setUpdate(true)}>
                  <i className='edit-button fa-solid fa-pen-to-square'> </i>{' '}
                </button>{' '}
                <button className='wrap-button' onClick={handleDelete}>
                  <i className='delete-button fa-solid fa-trash'> </i>{' '}
                </button>{' '}
              </div>
            )}{' '}
          </div>{' '}
          <div className='singlenote-info'>
            <p className='singlenote-author'> Author: {note.username} </p>{' '}
            <a href={problemUrl} className='singlenote-link'>
              {' '}
              Link{' '}
            </a>{' '}
          </div>{' '}
          <label className={`singlenote-tag singlenote-tag-${note.tag}`}>
            {' '}
            {note.tag}{' '}
          </label>{' '}
          <div className='singlenote-cat'>
            <ul className='cat'>
              {' '}
              {note.categories &&
                note.categories.map((cat) => (
                  <Link
                    key={cat}
                    to={`/notes?cat=${cat}`}
                    className='single-cate'
                  >
                    <li key={cat}> {cat} </li>{' '}
                  </Link>
                ))}{' '}
            </ul>{' '}
          </div>{' '}
          <div className='singlenote-content'>
            {' '}
            {body.map((str, idx) => (
              <p key={idx} className='note-content-p'>
                {' '}
                {str}{' '}
              </p>
            ))}{' '}
          </div>{' '}
          <Link to='/notes' className='goback-to-notes'>
            Go Back
          </Link>
        </div>
      )}{' '}
    </div>
  );
}
