export function getToken() {
    return JSON.parse(localStorage.getItem('user')).token;
}

export function getRefreshToken() {
    return JSON.parse(localStorage.getItem('user')).refreshtoken;
}

export function getUserId() {
    return JSON.parse(localStorage.getItem('user'))._id;
}