import './sidebar.css';
import { normalAxios } from '../../config/axiosConfig';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function SideBar({ setTitle }) {
  const [catg, setCatg] = useState();
  useEffect(() => {
    const fetchCats = async () => {
      const res = await normalAxios.get('/category');
      const sortedCats = res.data.sort((a, b) =>
        a.name.length === b.name.length
          ? a.name - b.name
          : a.name.length - b.name.length
      );
      setCatg(sortedCats);
    };
    fetchCats();
  }, []);

  return (
    <div className='sidebar'>
      <div className='sidebar-difficulty'>
        <label className='sidebar-title'> Difficulty</label>{' '}
        <ul className='category-section'>
          <Link to={'/notes?tag=Easy'} className='li-item-link'>
            <li className='li-item' onClick={() => setTitle('Easy')}>
              Easy
            </li>
          </Link>
          <Link to={'/notes?tag=Easy%2B'} className='li-item-link'>
            <li className='li-item' onClick={() => setTitle('Easy+')}>
              Easy+
            </li>
          </Link>
          <Link to={'/notes?tag=Medium'} className='li-item-link'>
            <li className='li-item' onClick={() => setTitle('Medium')}>
              Medium
            </li>
          </Link>
          <Link to={'/notes?tag=Medium%2B'} className='li-item-link'>
            <li className='li-item' onClick={() => setTitle('Medium+')}>
              Medium+
            </li>
          </Link>
          <Link to={'/notes?tag=Hard'} className='li-item-link'>
            <li className='li-item' onClick={() => setTitle('Hard')}>
              Hard
            </li>
          </Link>
          <Link to={'/notes?tag=Hard%2B'} className='li-item-link'>
            <li className='li-item' onClick={() => setTitle('Hard+')}>
              Hard+
            </li>
          </Link>
        </ul>{' '}
      </div>
      <div className='sidebar-category'>
        <label className='sidebar-title'>
          {' '}
          Categories
          <Link
            className='all-notes-link'
            to='/notes'
            onClick={() => setTitle('All')}
          >
            All
          </Link>
        </label>{' '}
        <ul className='category-section'>
          {catg?.map((cat) => (
            <Link key={cat.name} to={`/notes?cat=${cat.name}`}>
              <li className='li-item' onClick={() => setTitle(cat.name)}>
                {cat.name}
              </li>
            </Link>
          ))}
        </ul>{' '}
      </div>
    </div>

    // < div className="sidebar" > < /div>
    // <
    // div className="sidebar-difficulty" >
    //     <
    // label className="sidebar-title" > Difficulty: < /label>{" "} <
    // ul className="category-section" >
    //             <
    // Link className="li-item-link"
    //                 to={"/notes?tag=Easy"} >
    //                 <
    // li className="li-item" > Easy < /li> < /
    // Link > <
    // Link className="li-item-link"
    //                         to={"/notes?tag=Easy+"} >                     <
    // li className="li-item" > Easy + < /li> < /
    // Link > <
    // Link className="li-item-link"
    //                                 to={"/notes?tag=Medium"} >
    //                                 <
    // li className="li-item" > Medium < /li> < /
    // Link > <
    // Link className="li-item-link"
    //                                         to={"/notes?tag=Medium+"} >
    //                                         <
    // li className="li-item" > Medium + < /li> < /
    // Link > <
    // Link className="li-item-link"
    //                                                 to={"/notes?tag=Hard"} >
    //                                                 <
    // li className="li-item" > Hard < /li> < /
    // Link > <
    // Link className="li-item-link"
    //                                                         to={"/notes?tag=Hard+"} >
    //                                                         <
    // li className="li-item" > Hard + < /li> < /
    // Link > <
    // /ul>{" "} < /
    // div > <
    // div className="sidebar-category" >
    //                                                                 <
    // label className="sidebar-title" > Categories: < /label>{" "} <
    // ul className="category-section" > {
    //                                                                             catg ? .map((cat) =>(< Link key = {
    //             cat.name
    //         }
    //         to = {
    //             `/notes?cat=${
    //                 cat.name
    //             }`
    //         }
    //         className = "li-item-link" > <li className="li-item"> {
    //             cat.name
    //     } < /li> < /Link >))} < /ul>{" "} < /
    // div > </div>
  );
}
