import './signup.css';
import { useState, useEffect } from 'react';
import { normalAxios } from '../../config/axiosConfig';

export default function SignUp() {
  const [subscriber, setSubscriber] = useState({
    email: '',
    firstName: '',
    lastName: '',
  });
  const [feedback, setFeedBack] = useState('');
  const [errors, setErrors] = useState({});
  const [validated, setValidated] = useState(false);

  const validateForm = (subscriber) => {
    const error = {};
    const regexName = /^[a-zA-Z ]{2,30}$/;
    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!subscriber.firstName) {
      error.firstName = 'First name is required!';
    } else if (!regexName.test(subscriber.firstName)) {
      error.firstName = 'Invalid first name!';
    }
    if (!subscriber.lastName) {
      error.lastName = 'Last name is required!';
    } else if (!regexName.test(subscriber.firstName)) {
      error.lastName = 'Invalid last name!';
    }

    if (!subscriber.email) {
      error.email = 'Email address is required!';
    } else if (!regexEmail.test(subscriber.email)) {
      error.email = 'Invalid email address!';
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubscriber((prevMemeber) => {
      return {
        ...prevMemeber,
        [name]: value,
      };
    });
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setErrors(validateForm(subscriber));
    setValidated(true);
    setFeedBack('');
    // try {
    //   const res = await normalAxios.post('/signup', subscriber);
    //   setFeedBack(res.data);
    //   setTimeout(() => {
    //     window.location.replace('/notes');
    //   }, 2000);
    // } catch (err) {
    //   const error = err.response.data;
    //   setFeedBack(error.message);
    // }
  };
  useEffect(async () => {
    if (Object.keys(errors).length === 0 && validated) {
      try {
        const res = await normalAxios.post('/signup', subscriber);
        setFeedBack(res.data);
        setSubscriber({
          email: '',
          firstName: '',
          lastName: '',
        });
        setTimeout(() => {
          window.location.replace('/notes');
        }, 2000);
      } catch (err) {
        const error = err.response.data;
        setFeedBack(error.message);
      }
    }
  }, [errors]);

  return (
    <div className='signup-page'>
      <h1 className='signup-page-header'>Welcome</h1>
      <form action='' className='signup-form' onSubmit={handleSignUp}>
        <label htmlFor='' className='signup-input-label'>
          First Name
        </label>
        <input
          type='text'
          name='firstName'
          className='signup-input'
          placeholder='First Name'
          onChange={handleChange}
        />
        <p className='signup-form-error'>{errors.firstName}</p>
        <label htmlFor='' className='signup-input-label'>
          Last Name
        </label>
        <input
          type='text'
          name='lastName'
          className='signup-input'
          placeholder='Last Name'
          onChange={handleChange}
        />
        <p className='signup-form-error'>{errors.lastName}</p>
        <label htmlFor='' className='signup-input-label'>
          Your email address
        </label>
        <input
          type='email'
          name='email'
          className='signup-input'
          placeholder='Your email'
          onChange={handleChange}
        />
        <p className='signup-form-error'>{errors.email}</p>
        <button className='signup-page-button'>Sign Up</button>
      </form>
      {feedback && <p className='signup-error-message'>{feedback}</p>}
    </div>
  );
}
